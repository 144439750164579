import { TableBody, TableCell, TableRow } from '@mui/material';
import {
  AccTableClinicsType,
  AccTableInsuranceRowType,
  AccTableInsuranceType,
  BillingAccTableType,
} from '../../../../../../types/AccTableTypes';
import React, { useState } from 'react';
import * as S from '../../styles';
import { Dialog } from '../../../../../../components';
import ClaimList from './components';
import dayjs from 'dayjs';

type Props = {
  data: BillingAccTableType;
  index: number;
};

export type DialogClaimListProps = {
  clinicId: string;
  date: string;
  doctorId: string;
  insuranceType: string;
  insuranceName: string;
};

const AccTableBody: React.FC<Props> = ({ data, index }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState<DialogClaimListProps | null>(null);

  const handleChangeDialog = () => {
    setDialogProps(null);
    setOpenDialog(openDialog => !openDialog);
  };

  const handleClickOnCell = (
    clinic: AccTableClinicsType,
    row: AccTableInsuranceRowType,
    item: AccTableInsuranceType,
  ) => {
    setDialogProps({
      clinicId: clinic.clinickId,
      date: row.date,
      doctorId: data.doctorId,
      insuranceType: item.type,
      insuranceName: item.insurance,
    });
    setOpenDialog(openDialog => !openDialog);
  };

  return (
    <React.Fragment>
      <Dialog open={openDialog} onClose={handleChangeDialog}>
        <ClaimList onClose={handleChangeDialog} dialogProps={dialogProps} />
      </Dialog>
      <TableBody>
        <TableRow className="column">
          {index === 0 && (
            <TableCell className="dataCell whiteColor">
              {data.clinics[0].data[0].rows.map(date => {
                return (
                  <S.ClaimCount
                    key={date.date}
                    className={
                      dayjs(date.date).date() === dayjs(date.date).daysInMonth()
                        ? 'lastday'
                        : ''
                    }
                  >
                    {dayjs(date.date).format('MM/DD/YYYY ddd ')}
                  </S.ClaimCount>
                );
              })}
            </TableCell>
          )}
          {data.clinics.map((clinic, indexNum: number) => {
            return clinic.data.map((item: AccTableInsuranceType) => {
              return (
                <TableCell key={item.insurance + indexNum} className="dataCell">
                  {item.rows.map((row, mIndex) => {
                    return (
                      <S.ClaimCount
                        key={row.date}
                        onClick={() =>
                          row.claimsCount > 0 && handleClickOnCell(clinic, row, item)
                        }
                        className={
                          dayjs(row.date).date() === dayjs(row.date).daysInMonth()
                            ? 'lastday'
                            : ''
                        }
                      >
                        {row.claimsCount || '-'}
                      </S.ClaimCount>
                    );
                  })}
                </TableCell>
              );
            });
          })}
        </TableRow>
      </TableBody>
    </React.Fragment>
  );
};

export default AccTableBody;
