import { generateStylesConfig } from './generateStylesConfig';
import { Text, View } from '@react-pdf/renderer';
import React, { ReactNode } from 'react';
import dayjs from 'dayjs';

export const HeaderPdf = () => {
  const s = generateStylesConfig();
  return (
    <View style={[s.row, { justifyContent: 'space-between' }]}>
      <View style={[s.column, { alignItems: 'flex-start' }]}>
        <Text style={s.header}>HEALTH INSURANCE CLAIM FORM</Text>
        <Text style={s.subHeader}>
          APPROVED BY NATIONAL UNIFORM CLAIM COMMITTEE (NUCC) 02/12
        </Text>
        <View style={[s.row, { width: 562, justifyContent: 'space-between' }]}>
          <View style={[s.row, { justifyContent: 'flex-start' }]}>
            <View style={s.square} />
            <View style={s.square} />
            <View style={s.square} />
            <Text style={s.title}>PICA</Text>
          </View>
          <View style={[s.row, { justifyContent: 'flex-start', gap: 0 }]}>
            <Text style={s.title}>PICA</Text>
            <View style={s.square} />
            <View style={s.square} />
            <View style={s.square} />
          </View>
        </View>
      </View>

      <View style={s.column}>
        <Text style={[s.arrow, { transform: 'rotate(-90deg)' }]}>&mdash;&gt;</Text>
        <Text style={s.columnRow}>CARRIER</Text>
        <Text style={[s.arrow, { transform: 'rotate(90deg)' }]}>&mdash;&gt;</Text>
      </View>
    </View>
  );
};

export const FooterPdf = () => {
  const { row, title } = generateStylesConfig();

  return (
    <View style={[row, { justifyContent: 'space-between' }]}>
      <Text style={title}>NUCC Instruction Manual available at: www.nucc.org</Text>
      <Text style={[title, { fontWeight: 700 }]}>PLEASE PRINT OR TYPE</Text>
      <Text style={title}>APPROVED OMB-0938-1197 FORM 1500 (02-12)</Text>
    </View>
  );
};

export const CheckBoxPdf = ({
  checked,
  subTitle,
  prefix,
}: {
  checked: boolean;
  subTitle?: string;
  prefix?: string;
}) => {
  const s = generateStylesConfig();

  return (
    <View style={s.row}>
      {!!prefix && <Text style={s.subTitle}>{prefix}</Text>}
      <View style={s.square}>
        <Text style={s.check}>{checked ? 'X' : ''}</Text>
      </View>
      {!!subTitle && <Text style={s.subTitle}>{subTitle}</Text>}
    </View>
  );
};

export const CellPdf = ({
  children,
  width = 205,
  height = 25,
}: {
  children: ReactNode;
  width?: number;
  height?: number;
}) => {
  const { row, cell } = generateStylesConfig();

  return (
    <View
      style={[
        row,
        cell,
        { justifyContent: 'space-between', width, height, alignItems: 'flex-start' },
      ]}
    >
      {children}
    </View>
  );
};

export const ColumnPdf = ({
  name,
  content,
  width = 205,
  height = 25,
}: {
  name: string;
  content?: string | null;
  width?: number | string;
  height?: number;
}) => {
  const { column, title, cell, text } = generateStylesConfig();

  return (
    <View style={[column, cell, { width, height, alignItems: 'flex-start' }]}>
      <Text style={[title, { paddingBottom: 1 }]}>{name}</Text>
      <Text style={[text, { paddingLeft: 5 }]}>{content}</Text>
    </View>
  );
};

export const SimplyCellPdf = ({
  name,
  subName,
  content,
}: {
  name?: string;
  subName?: string;
  content?: string | null;
}) => {
  const { title, row, column, text } = generateStylesConfig();

  return (
    <View style={[column, { alignItems: 'flex-start', flex: 1 }]}>
      <View style={[row, { justifyContent: 'space-between', width: '100%' }]}>
        <Text style={[title, { paddingBottom: 1 }]}>{name}</Text>
        {!!subName && <Text style={[title, { paddingBottom: 1 }]}>{subName}</Text>}
      </View>

      <Text style={[text, { paddingLeft: 5 }]}>{content}</Text>
    </View>
  );
};

export const BirthDayPdf = ({ date }: { date?: string | null }) => {
  const { row, verticalBorder, column, subTitle, text } = generateStylesConfig();

  return (
    <View style={[row, { gap: date ? 3 : 8, alignItems: 'flex-start' }]}>
      <View style={[column, { justifyContent: 'flex-start' }]}>
        <Text style={[subTitle, { padding: 0 }]}>MM</Text>
        <Text style={[text, { paddingTop: -2 }]}>
          {date ? dayjs(date).format('MM') : ''}
        </Text>
      </View>
      <View style={[verticalBorder]} />
      <View style={column}>
        <Text style={[subTitle, { padding: 0 }]}>DD</Text>
        <Text style={[text, { paddingTop: -2 }]}>
          {date ? dayjs(date).format('DD') : ''}
        </Text>
      </View>
      <View style={[verticalBorder]} />
      <View style={column}>
        <Text style={[subTitle, { padding: 0 }]}>YYYY</Text>
        <Text style={[text, { paddingTop: -2 }]}>
          {date ? dayjs(date).format('YYYY') : ''}
        </Text>
      </View>
    </View>
  );
};

export const CityStatePdf = ({
  city,
  state,
  isCell,
}: {
  city?: string | null;
  state?: string | null;
  isCell?: boolean;
}) => {
  const { redRow } = generateStylesConfig();

  return (
    <View style={[redRow, { width: '100%' }]}>
      {isCell ? (
        <ColumnPdf name={'CITY'} content={city} width={'60%'} />
      ) : (
        <SimplyCellPdf name={`CITY`} content={city} />
      )}
      <ColumnPdf name={'STATE'} content={state} width={'40%'} />
    </View>
  );
};

export const ZipPhonePdf = ({
  zip,
  phone,
  isCell,
}: {
  zip?: string | null;
  phone?: string | null;
  isCell?: boolean;
}) => {
  const { redRow } = generateStylesConfig();

  return (
    <View style={[redRow, { width: '100%' }]}>
      {isCell ? (
        <ColumnPdf name={'ZIP CODE'} content={zip} width={'45%'} />
      ) : (
        <SimplyCellPdf name={`ZIP CODE`} content={zip} />
      )}
      <ColumnPdf name={'TELEPHONE (Include Area Code)'} content={phone} width={'55%'} />
    </View>
  );
};
