import { DoctorClaimResponse } from 'types/AccTableTypes';
import { Clinics, Patients } from '../../../types/ClinicTypes';
import { TicketType } from '../../../types/TicketType';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id: string;
};

export const billingApi = api.injectEndpoints({
  endpoints: builder => ({
    billingAccTableGet: builder.query<any, any>({
      query: ({ ...params }) => ({
        url: `billing/accounting-table`,
        params: { ...params },
      }),
      providesTags: ['Billing'],
    }),
    doctorClaimList: builder.mutation<Response<DoctorClaimResponse>, any>({
      query: ({ ...params }) => ({
        url: `/billing/doctor-claims`,
        method: 'GET',
        params: { ...params },
      }),
      // invalidatesTags: ['Clinics'],
    }),
  }),
});

export const { useBillingAccTableGetQuery, useDoctorClaimListMutation } = billingApi;
