import { useDoctorClaimListMutation } from 'store/api/billing/billingApi';
import * as S from './styles';
import { useEffect } from 'react';
import { DialogClaimListProps } from '../AccTableBody';
import { DoctorClaimResponse } from 'types/AccTableTypes';
import { useNavigate } from 'react-router-dom';

type Props = {
  onClose: () => void;
  dialogProps: DialogClaimListProps | null;
};

const ClaimList: React.FC<Props> = ({ onClose, dialogProps }) => {
  const [getClaimList, statusClaimList] = useDoctorClaimListMutation({});

  useEffect(() => {
    if (dialogProps) {
      getClaimList({
        clinicId: dialogProps.clinicId,
        date: dialogProps.date,
        doctorId: dialogProps.doctorId,
        insuranceType: dialogProps.insuranceType,
        insuranceName: dialogProps.insuranceName,
      });
    }
  }, [dialogProps]);

  const navigate = useNavigate();

  return (
    <S.Content>
      <S.Article>Claim list</S.Article>
      <S.Content>
        {statusClaimList.data?.rows.map((item: DoctorClaimResponse) => {
          return (
            <S.Row key={item.id} onClick={() => navigate(`/claim-form/${item.id}`)}>
              <S.Text>Claim {item.id}</S.Text>
              <S.PatientName>• {item.patientName}</S.PatientName>
            </S.Row>
          );
        })}
      </S.Content>
    </S.Content>
  );
};

export default ClaimList;
